import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Admin from "@/views/Admin.vue";
import AdminUserGrid from "@/views/admin/user/Grid.vue";
import AdminUserForm from "@/views/admin/user/Form.vue";
import AdminProjectGrid from "@/views/admin/projects/Grid.vue";
import AdminProjectForm from "@/views/admin/projects/Form.vue";
import AdminProjectModerationGrid from "@/views/admin/projects-moderation/Grid.vue";
import AdminProjectModerationForm from "@/views/admin/projects-moderation/Form.vue";
import AdminProjectRejectedGrid from "@/views/admin/projects-rejected/Grid.vue";
import AdminProjectRejectedForm from "@/views/admin/projects-rejected/Form.vue";

import { name, version } from "../../package.json";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      guest: true
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/users",
    name: "adminUser",
    component: AdminUserGrid,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/users/:type",
    name: "adminUserForm",
    component: AdminUserForm,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/projects",
    name: "adminProjects",
    component: AdminProjectGrid,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/projects/:type",
    name: "adminProjectForm",
    component: AdminProjectForm,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/projects-moderation",
    name: "adminProjectsModeration",
    component: AdminProjectModerationGrid,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/projects-moderation/:type",
    name: "adminProjectModerationForm",
    component: AdminProjectModerationForm,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/projects-rejected",
    name: "adminProjectsRejected",
    component: AdminProjectRejectedGrid,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/projects-rejected/:type",
    name: "adminProjectRejectedForm",
    component: AdminProjectRejectedForm,
    meta: {
      requiresAuth: true,
      is_admin : true
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let user = ''
  if (window.localStorage.getItem(`${name}-${version}`)) {
    user = JSON.parse(atob(window.localStorage.getItem(`${name}-${version}`)))
  }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (user.name == undefined) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      if(to.matched.some(record => record.meta.is_admin)) {
        if ([1, 2].indexOf(user.role) >= 0) {
          next()
        } else{
          next({ name: 'home'})
        }
      }else {
        next()
      }
    }
  } else {
    next()
  }
});

export default router;
