<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <p class="label-head"><strong>Avaliação:</strong> 
          <vue-star-rating v-model="form.rate" :show-rating="false" :star-size="15" :border-width="3" border-color="#d8d8d8" :rounded-corners="true" ></vue-star-rating></p>
      </div>
       <div class="md-layout-item">
        <p class="label-head"><strong>Usuário:</strong> {{ form.user }}</p>
      </div>
      <div class="md-layout-item">
        <p class="label-head"><strong>Criado em:</strong> {{ form.created_at | moment('MM/DD/YYYY hh:mm') }}</p>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="status">Status</label>
          <md-select v-model="form.status" name="status" id="status">
            <md-option value="0">Inativo</md-option>
            <md-option value="2">Recusado</md-option>
            <md-option value="1">Ativo</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <hr />
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="first-name">Nome</label>
          <md-input name="first-name" id="first-name" autocomplete="given-name" v-model="form.name" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="project_type">Tipo de projeto</label>
          <md-select v-model="form.project_type" name="project_type" id="project_type">
            <md-option v-for="item in tipoProjeto" :value="item" :key="item">{{ item }}</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="about">Sobre</label>
          <md-textarea name="about" autocomplete="given-name" md-autogrow v-model="form.about"></md-textarea>
        </md-field>
      </div>
       <div class="md-layout-item">
       <md-field>
          <label for="is_3d">Projeto com imagens 3D?</label>
          <md-select v-model="form.is_3d" name="is_3d" id="is_3d">
            <md-option value="0">Não</md-option>
            <md-option value="1">Sim</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="city">Cidade</label>
          <md-input name="city" autocomplete="given-name" v-model="form.city" />
        </md-field>
      </div>
      <div class="md-layout-item">        
          <md-field>
          <label for="uf">Estado</label>
          <md-select v-model="form.uf" name="uf" id="uf">
            <md-option value="ac">Acre</md-option>
            <md-option value="al">Alagoas</md-option>
            <md-option value="ap">Amapá</md-option>
            <md-option value="am">Amazonas</md-option>
            <md-option value="ba">Bahia</md-option>
            <md-option value="ce">Ceará</md-option>
            <md-option value="df">Distrito Federal</md-option>
            <md-option value="es">Espírito Santo</md-option>
            <md-option value="go">Goiás</md-option>
            <md-option value="ma">Maranhão</md-option>
            <md-option value="mt">Mato Grosso</md-option>
            <md-option value="ms">Mato Grosso do Sul</md-option>
            <md-option value="mg">Minas Gerais</md-option>
            <md-option value="pa">Pará</md-option>
            <md-option value="pb">Paraíba</md-option>
            <md-option value="pr">Paraná</md-option>
            <md-option value="pe">Pernambuco</md-option>
            <md-option value="pi">Piauí</md-option>
            <md-option value="rj">Rio de Janeiro</md-option>
            <md-option value="rn">Rio Grande do Norte</md-option>
            <md-option value="rs">Rio Grande do Sul</md-option>
            <md-option value="ro">Rondônia</md-option>
            <md-option value="rr">Roraima</md-option>
            <md-option value="sc">Santa Catarina</md-option>
            <md-option value="sp">São Paulo</md-option>
            <md-option value="se">Sergipe</md-option>
            <md-option value="to">Tocantins</md-option>
          </md-select>
        </md-field>
      </div>
      
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="credit_maker">Crédito marceneiro</label>
          <md-input name="credit_maker" autocomplete="given-name" v-model="form.credit_maker" />
        </md-field>
        <md-field>
          <label for="link_maker">Link marceneiro</label>
          <md-input name="link_maker" autocomplete="given-name" v-model="form.link_maker" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="credit_photography">Crédito fotografo</label>
          <md-input name="credit_photography" autocomplete="given-name" v-model="form.credit_photography" />
        </md-field>
        <md-field>
          <label for="link_photography">Link fotografo</label>
          <md-input name="link_photography" autocomplete="given-name" v-model="form.link_photography" />
        </md-field>
      </div>
    </div>
    <gallery title="Galeria" type="gallery" v-model="form.gallery" />
    <gallery title="Produtos" type="product" v-model="form.products" />
    <hr />
    <div class="md-layout-item">
      <md-button to="/admin/projects-moderation" v-if="moderation" class="back">Voltar</md-button>
      <md-button to="/admin/projects-rejected" v-if="rejected" class="back">Voltar</md-button>
      <md-button to="/admin/projects" v-if="!moderation" class="back">Voltar</md-button>
      <md-button @click="update">Salvar</md-button>
    </div>
    <md-snackbar
      md-persistent
      md-position="center"
      :md-active.sync="showSnackbar"
    >
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import axios from "axios";
import router from "@/router";
import Gallery from "./Gallery";
import VueStarRating from "vue-star-rating";

export default {
  name: "Form",
  props: ["moderation", "rejected"],
  mixins: [validationMixin],
  components: {
    Gallery,
    VueStarRating
  },
  data() {
    return {
      tipoProjeto: [],
      form: {},
      project_types: [{"id":1,"name":"Cozinhas"},{"id":2,"name":"Bares"}],
      status: false,
      button: parseInt(this.$route.params.type) > 0 ? 'Update' : 'Create',
      message: "",
      showSnackbar: false,
      users: this.$store.state.admin.users,
      user: this.$store.state.user,
      rating: "No Rating Selected",
      currentRating: "No Rating",
      currentSelectedRating: "No Current Rating",
      boundRating: 3,
    }
  },
  beforeMount() {
    this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'tipo_de_projeto'),
        { pageSize : 100000 }
    ).then(response => {
      let tipoProjeto = [];
      response.results.forEach(function(value){
        tipoProjeto.push(value.data.titulo[0].text);
      });
      this.tipoProjeto = tipoProjeto;
    });
  },
  mounted() {
    if (parseInt(this.$route.params.type) > 0) {
      this.$store.commit('toggleLoading')
      if (this.users.length < 1) {
        axios
          .get(`${window.api}projects/${parseInt(this.$route.params.type)}/`)
          .then(({ data }) => {
            const project = data.find((item) => item.id === parseInt(this.$route.params.type));
            this.form = project;
            this.status = this.form.status == 1 ? true : false;
            this.$store.commit('toggleLoading');
          })
      } else {
        const project = this.users.filter((item) => item.id === parseInt(this.$route.params.type));
        this.form = project[0];
        this.form.products = JSON.parse(project[0].products);
        this.status = this.form.status == 1 ? true : false;
        this.form.gallery = JSON.parse(project.gallery);
        this.$store.commit('toggleLoading')
      }
    }
  },
  methods: {
    update() {
      this.$store.commit("toggleLoading");
      // this.form.status = this.status ? 1 : 0;
      return axios
        .post(
          `${window.api}projects/${this.form.id}/admin/edit/`,
          this.form
        )
        .then(() => {

          let routerName = 'adminProjects';
          if (this.moderation) {
            routerName += 'Moderation';
          } else if (this.rejected) {
            routerName += 'Rejected';
          }

          router.push({ name: routerName });

        })
        .catch(() => {
          this.message = "Alguma coisa deu errado, tente novamente mais tarde";
          this.showSnackbar = true;
        });
    },
  setRating: function(rating) {
      this.rating = "You have Selected: " + rating + " stars";
    },
    showCurrentRating: function(rating) {
      this.currentRating = (rating === 0) ? this.currentSelectedRating : "Click to select " + rating + " stars"
    },
    setCurrentSelectedRating: function(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    toggle: function(val) {
      this.form.status = val;
      this.status = val;
    }
  }
};
</script>

<style scoped>
.back {
  float: left;
}
.save {
  float: right;
}
.label-user{
  margin-top: 16px;
  font-size: 16px;
  display: block;
}
.vue-star-rating{
  display: inline-block;
  position: relative;
  top: 4px;
  left: 4px;
  margin-top: -3px;
}
</style>
