import Vue from "vue";
import Vuex from "vuex";
import { name, version } from "../../package.json";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    isLoading: false,
    grid: [],
    lines: [],
    historical: [],
    sortGrid: [],
    sortFiltered: [],
    done: false,
    lowestBaselineYear: 0,
    dataQualityIndex: 0,
    dataCompletnessIndex: 0,
    gridHealth: [],
    fullViewMode: false,
    total: 0,
    page: 0,
    selectedProjects: '',
    selectedCountries: [],
    selectedYears: [],
    headers: [],
    selectedEnterprise: '',
    unsavedData: false,
    project: [],
    enterprisesCount: 0,
    RevenueIncremental: 0,
    fullTimeJobsCreated: 0,
    projectInfo: {},
    admin: {
      projects: [],
      users: []
    },
    selectedRows: [],
    modal_show: false,
    modal_index: 0,
    modal_img: '',
  },
  mutations: {
    togglePage(state, value) {
      state.page = value
    },
    projectInfo(state, value) {
      state.projectInfo = value
    },
    toggleFullTimeJobsCreated(state, value) {
      state.fullTimeJobsCreated = value;
    },
    toggleEnterprisesCount(state, value) {
      state.enterprisesCount = value;
    },
    toggleRevenueIncremental(state, value) {
      state.revenueIncremental = value;
    },
    toggleBaselineYear(state, value) {
      state.lowestBaselineYear = value;
    },
    toggleFullView(state, value) {
      state.fullViewMode = value;
    },
    toggleSelectRows(state, values) {
      state.selectedRows = values;
    },
    updateLines(state, values) {
      state.lines = values;

      if (state.user.id) {
        axios.post(
          `${window.api}dataEntry/${state.user.id}/create/?jwt=${state.user.token}`, 
          {grid: state.lines},
        )
      }
    },
    removeRows(state) {
      const grid = state.lines
      Object.keys(state.selectedRows).forEach((k) => {
        let index = 0
        grid.map((item, i) => {
          if (item.id === state.selectedRows[k])
            index = i;
        })
        grid.splice(index, 1);
      });
      state.selectedRows = []
      state.lines = grid
    },
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
    disabledLoading(state) {
      state.isLoading = false;
    },
    showImage(state, value) {
      state.modal_img = value.img;
      state.modal_index = 1;
      state.modal_show = true;
    },
    handleHideImage(state) {
      state.modal_img = '';
      state.modal_index = 0;
      state.modal_show = false;
    },
    toggleLoggedUser(state, value) {
      state.user = value
      window.localStorage.setItem(`${name}-${version}`, btoa(JSON.stringify(value)));
      state.lines = [];
      state.dataQualityIndex = 0;
      state.dataCompletnessIndex = 0;
      state.fullViewMode = false;
    },
    toggleHeader(state, value) {
      state.headers = value;
    },
    addLine(state) {
      const grid_tmp = state.lines;
      grid_tmp.push([])
      this.lines = grid_tmp;
    },
    updateGrid(state, value) {
      state.lines = value;
    },
    recoverHistory(state, value) {
      state.lines = value;
    },
    removeHistorical(state) {
      const grid = state.lines
      grid.map((item) => {
        delete item.historical;
        return item
      })
      state.lines = grid;
    },
    importFile(state, value) {
      const grid = value;
      const historical = state.lines;
      const gridEnterprises = {};
      Object.keys(grid).forEach((k) => {
        gridEnterprises[grid[k]['Enterprise-Level Data']['Client or Company Name'].toString().trim()] = grid[k]
      });
      Object.keys(historical).forEach((k) => {
        if (gridEnterprises[historical[k]['Enterprise-Level Data']['Client or Company Name'].value.trim()]) {
          const tmp = historical[k];
          historical[k] = gridEnterprises[historical[k]['Enterprise-Level Data']['Client or Company Name'].value.trim()]
          historical[k]['Historical'] = tmp
          gridEnterprises[historical[k]['Historical']['Enterprise-Level Data']['Client or Company Name'].value.trim()] = null
        }
      });
      Object.keys(gridEnterprises).forEach((k) => {
        if (gridEnterprises[k] !== null) {
          historical.push(gridEnterprises[k])  
        }
      });
      state.lines = historical;
    },
    toggleViewMode(state) {
      state.fullViewMode = !state.fullViewMode;
    },
    updateQuality(state, indicators) {
      state.dataQualityIndex = indicators.quality;
      state.responseRateIndex = indicators.responseRate;
    },
    updateCell(state, cell) {
      if (!state.lines[cell.row])
        state.lines[cell.row] = {}
      if (!state.lines[cell.row][cell.title])
        state.lines[cell.row][cell.title] = {}
      if (!state.lines[cell.row][cell.title][cell.name])
        state.lines[cell.row][cell.title][cell.name] = {}
      state.lines[cell.row][cell.title][cell.name].value = cell.value
    },
    toggleUnsavedData(state) {
      state.unsavedData = !state.unsavedData;
    },
    toggleUnsavedDataTrue(state) {
      state.unsavedData = true;
    },
    selectProject(state, value) {
      state.selectedProjects = value;
      const sortGrid = []
      Object.keys(state.lines).forEach((k) => {
        if (state.lines[k]['Enterprise-Level Data'] && state.lines[k]['Enterprise-Level Data']["Partner Project ID"])
          if (state.selectedProjects === state.lines[k]['Enterprise-Level Data']["Partner Project ID"].value)
            sortGrid.push(state.lines[k])
      });
      state.sortGrid = sortGrid;
      state.sortFiltered = sortGrid;
      this.commit("updateSortGrid");
    },
    updateSortGrid(state) {
      state.done = true;
    },
    selectYear(state, value) {
      state.selectedYears = value;
    },
    selectEnterprise(state, enterprise) {
      state.selectedEnterprise = enterprise;
    },
    toogleAdminData(state, value) {
      state.admin = {...state.admin, value};
    }
  },
});
