import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import CountryFlag from 'vue-country-flag'
import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver' // Update this path if necessary

const accessToken = 'MC5YOXlwbmhJQUFDRUE5bEFI.77-977-9blnvv71ecxLvv70kRC_vv73vv73vv71M77-9bSd_UO-_ve-_ve-_ve-_vT_vv73vv70877-9axs'
const endpoint = 'https://guararapes.cdn.prismic.io/api/v2'

Vue.component('vue-country-flag', CountryFlag)

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
