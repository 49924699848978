<template>
  <main>
    <md-table v-model="data">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Projetos</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No users with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single" :md-sort-fn="customSort">
        <md-table-cell md-label="ID">{{item.id}}</md-table-cell>
        <md-table-cell md-label="Name">{{item.name}}</md-table-cell>
        <md-table-cell md-label="Criado em" md-sort-by="created_at">{{item.created_at | moment("from", "now")}}</md-table-cell>
        <md-table-cell md-label="Editado em" v-if="moderation">{{item.edited_at | moment("from", "now")}}</md-table-cell>
        <md-table-cell md-label="Status">{{item.status == 0 ? 'Inactive' : (item.status == 2 ? 'Rejected' : 'Active')}}</md-table-cell>
        <md-table-cell md-label="">
          <md-button :to="`/admin/projects/${item.id}`" v-if="!moderation && !rejected">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button :to="`/admin/projects-moderation/${item.id}`" v-if="moderation">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button :to="`/admin/projects-rejected/${item.id}`" v-if="rejected">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button @click="remove(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)) || toLower(item.id).includes(toLower(term)) || toLower(item.user).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  props: ['moderation', 'rejected'],
  data() {
    return {
      search: null,
      grid: [],
      user: this.$store.state.user,
      data: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    customSort (value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }

        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    remove(id) {
      if ( confirm('Deseja remover este projeto?') ) {
        this.$store.commit('toggleLoading')
        axios
          .post(`${window.api}project/delete/${id}/`)
          .then(() => {
            this.$store.commit('toggleLoading')
            this.getData();
          });
      }
    },
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    getData() {
      this.$store.commit('toggleLoading');

      let url = this.moderation ? `${window.api}projects/inactive/` : `${window.api}projects/active/`;
      url = this.rejected ? `${window.api}projects/rejected/` : url;

      axios
        .get(url)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
          this.$store.commit('toogleAdminData', {users: this.data})
        });
    },
  },
}
</script>

<style scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
