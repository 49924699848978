<template>
  <main>
    <md-list>
      <md-list-item exact to="/admin">
        <md-icon>home</md-icon>
        <span class="md-list-item-text">Home</span>
      </md-list-item>
      <md-list-item to="/admin/projects-moderation">
        <md-icon>feedback</md-icon>
        <span class="md-list-item-text">Projetos Moderação</span>
      </md-list-item>
      <md-list-item to="/admin/projects-rejected">
        <md-icon>feedback</md-icon>
        <span class="md-list-item-text">Projetos Recusados</span>
      </md-list-item>
      <md-list-item to="/admin/projects">
        <md-icon>feedback</md-icon>
        <span class="md-list-item-text">Projetos</span>
      </md-list-item>
      <md-list-item to="/admin/users">
        <md-icon>person</md-icon>
        <span class="md-list-item-text">Usuários</span>
      </md-list-item>
    </md-list>
  </main>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      user: this.$store.state.user
    }
  }
}
</script>
