<template>
  <main>
    <span class="md-title">Meu ambiente</span>
    <div class="utils">
      <md-button v-on:click="logout()">
        <md-icon>exit_to_app</md-icon>
      </md-button>
    </div>
  </main>
</template>

<script>
import router from "@/router";

export default {
  name: 'Bar',
  methods: {
    logout() {
      this.$store.commit("toggleLoggedUser", {});
      router.push({ name: "home" });
    },
  }
}
</script>

<style scoped>
main {
  display: flex;
  width: 100%;
  align-items: center;
}

main .md-title {
  display: flex;
  flex-grow: 1;
}
</style>