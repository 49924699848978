<template>
  <div class="md-layout">
    <div class="md-layout-item gallery-item">
      <p>
        <strong>{{title}}</strong>
      </p>
      <div class="products-modal" v-show="showProductsModal" v-if="this.type === 'product'">
        Escolha um produto
        <md-button @click="showProductsModal = false" style="position: absolute; top: 5px; right: 5px">
          <md-icon>close</md-icon>
        </md-button>
        <div class="products-modal-content">
          <ul class="grid">
            <li v-for="(item,index) in products" :key="index" style="display: inline-block; width: 200px; margin-bottom: 10px">
              <img :src="item.data.imagem_principal.url" style="width:200px; cursor:pointer;" @click="showImage(item.data.imagem_principal.url)"  />
              <div>
                <h5 class="grid__item-text">{{item.data.nome[0].text}}</h5>
                <span class="grid__item-category">Linha {{item.data.categoria_produto.uid}}</span>
              </div>
              <md-button class="grid__item-button" @click="addProduct(item)">
                <md-icon>add</md-icon>
              </md-button>
            </li>
          </ul>
        </div>
      </div>
      <ul class="grid">
        <li v-for="(item, index) in images" :key="index" style="width: 200px; display: inline-block;">
          <img :src="getItemImage(item)" style="width:200px; cursor:pointer;" @click="showImage(getItemImage(item))"  />
          <div v-if="item.data || item.node">
            <h5 class="grid__item-text">{{getItemText(item)}}</h5>
            <span class="grid__item-category">Linha {{getItemCategory(item)}}</span>
          </div>
          <md-button class="grid__item-button" @click="handleImageRemove(index)">
            <md-icon>delete</md-icon>
          </md-button>
        </li>
        <li class="add-item" style="width: 100px; display: inline-block;">
          <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked"/>
          <md-button v-if="type === 'product'" @click="openModal" style="margin-top: 55px">
            <md-icon style="font-size: 60px !important;">add</md-icon>
          </md-button>
          <md-button v-if="type === 'gallery'" @click="handleGallery" style="margin-top: 55px">
            <md-icon style="font-size: 60px !important;">add</md-icon>
          </md-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ProjectGallery",
  data: function () {
    return {
      images: [],
      products: [],
      showProductsModal: false
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    type:{
      type: String
    },
    value: {
      type: String
    }
  },
  mounted() {
    if (!this.value){
      this.images = [];
    }
  },
  watch: {
    value: function(val) {
      if(!val){
        this.images = [];
      }else{
        this.images = JSON.parse(val);
      }
    },
    images: function(){
      let imagesIndex = [];
      this.images.forEach(function(item){
        imagesIndex.push(item.uid);
      });

      this.products = this.products.filter(function(element){
        return imagesIndex.indexOf(element.uid) === -1
      });
    }
  },
  created() {
    if(this.type === 'product'){
      this.getContent()
    }
  },
  methods: {
    openModal(){
      this.showProductsModal = true
    },
    addProduct(item){
      let designs = [];
      let tonalidades = [];

      item.data.designs.forEach(function(element){
        designs.push({design_produto: {uid: element.uid}})
      });
      item.data.tonalidades.forEach(function(element){
        tonalidades.push({tonalidade_produto: {uid: element.tonalidade_produto.uid}})
      });

      this.images.push({
          data: {
            categoria_produto: {uid: item.data.categoria_produto.uid},
            designs: designs,
            imagem_principal: {url: item.data.imagem_principal.url},
            nome: [{text: item.data.nome[0].text}],
            tonalidades: tonalidades
          },
          uid: item.uid
      });
      this.$emit("input", JSON.stringify(this.images));
    },
    async getContent() {
      if(this.type === 'product'){
        const productTypes = ['decorativos', 'aris'];

        const res1 = await this.$prismic.client.query(
            this.$prismic.Predicates.at('document.type', 'produto'),
            { pageSize : 100 }
        );
        const res2 = await this.$prismic.client.query(
            this.$prismic.Predicates.at('document.type', 'produto'),
            { pageSize : 100, page: 2 }
        );

        const productsPage1 = res1.results.filter((value) => productTypes.includes(value.data.tipo_de_produto.slug) && value.data.tipo_de_produto.lang === "pt-br");
        const productsPage2 = res2.results.filter((value) => productTypes.includes(value.data.tipo_de_produto.slug) && value.data.tipo_de_produto.lang === "pt-br");
        
        this.products = [...productsPage1, ...productsPage2].flat();
      }
    },
    showImage(img) {
      this.$store.commit("showImage", { img:img });
    },
    handleProduct(){

    },
    handleGallery(){
      this.$refs.fileInput.click();
    },
    onFilePicked(event){
      let formData = new FormData();
      formData.append("file[0]", event.target.files[0]);
      axios.post(
          `${window.api}upload/`,
          formData
      ).then(response => {
        this.images.push({url:response.data.imgs[0].url});
        this.$emit("input", JSON.stringify(this.images));
      });
    },
    handleImageRemove(imageIndex) {
      const images = this.images.filter((_, index) => index !== imageIndex);
      this.$emit("input", JSON.stringify(images));
    },
    getItemImage(item) {
      const { url, data = {}, node = {} } = item || {};
      return url || (data.imagem_principal || {}).url || ((node.data || {}).imagem_principal || {}).url;
    },
    getItemText(item) {
      const value = item.data || item.node.data;
      return value.nome[0].text;
    },
    getItemCategory(item) {
      const value = item.data || item.node.data;
      const category = value.categoria_produto.uid;
      return category.split('-').join(" ");
    }
  }
}
</script>

<style scoped>
.gallery-item{
  position: relative;
}
.products-modal{
  background: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 500px;
  height: 100%;
  z-index: 99999;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.products-modal-content{
  max-height: calc(100% - 40px);
  overflow-y: auto;
  margin-top: 10px;
}
.add-item{
  text-align: center;
}
.grid {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
}
.grid__item-button {
  width: 100%;
  margin: 10px 0;
}
.grid__item-text {
  margin: 8px 0;
}
.grid__item-category {
  margin: 0;
}
</style>
