<template>
  <main>
    <div class="utils">
      <md-button to="/admin/users/new" class="md-dense md-raised md-primary">Add</md-button>
      <md-button class="md-dense md-raised" @click="csvExport">Exportar</md-button>
    </div>
    <md-table v-model="data">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Usuários</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
          :md-label="grid.length < 1 ? 'Nothing here yet' : `No users with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="ID">{{item.id}}</md-table-cell>
        <md-table-cell md-label="Name">{{item.name}}</md-table-cell>
        <md-table-cell md-label="E-mail">{{item.email}}</md-table-cell>
        <md-table-cell md-label="Status">{{item.status === 0 ? 'Inactive' : 'Active'}}</md-table-cell>
        <md-table-cell md-label="">
          <md-button :to="`/admin/users/${item.id}`">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button @click="remove(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)) || toLower(item.email).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      user: this.$store.state.user,
      data: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    remove(id) {
      if ( confirm('Do you want to remove this user?') ) {
        this.$store.commit('toggleLoading')
        axios
            .post(`${window.api}users/delete/${id}/`)
            .then(() => {
              this.$store.commit('toggleLoading')
              this.getData();
            });
      }
    },
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
          .get(`${window.api}users/`)
          .then(response => {
            this.data = this.grid = response.data;
            this.$store.commit('toggleLoading')
            this.$store.commit('toogleAdminData', {users: this.data})
          });
    },
    csvExport: function () {

      let csvContent = '"ID","Nome","Sobrenome","Email","Telefone","Cidade","UF","Status","Profissão","Nome Empresarial","Email Empresarial","Telefone Empresarial","Sobre a Empresa","Website Empresarial","Facebook Empresarial","Instagram Empresarial","Linkedin Empresarial","Pinterest Empresarial","Avatar Empresarial"\n';

      this.data.forEach(function(d){
        csvContent += '"' + d.id + '",' +
            '"' + d.name + '",' +
            '"' + d.surname + '",' +
            '"' + d.email + '",' +
            '"' + d.phone + '",' +
            '"' + d.city + '",' +
            '"' + d.uf + '",' +
            '"' + (d.status ? 'Ativo' : 'Inativo') + '",' +
            '"' + d.profession + '",' +
            '"' + d.company_name + '",' +
            '"' + d.company_email + '",' +
            '"' + d.company_phone + '",' +
            '"' + d.company_about.replace(/(\r\n|\n|\r)/gm, "") + '",' +
            '"' + d.company_website + '",' +
            '"' + d.company_facebook + '",' +
            '"' + d.company_instagram + '",' +
            '"' + d.company_linkedin + '",' +
            '"' + d.company_pinterest + '",' +
            '"' + d.company_avatar + '"\n';
      });

      var csvData = new Blob([csvContent], { type: 'text/csv' });
      var csvUrl = URL.createObjectURL(csvData);

      let link = document.createElement("a");
      link.setAttribute("href", csvUrl);
      link.setAttribute("download", "usuarios.csv");
      document.body.appendChild(link);
      link.click();

    }
  },
}
</script>

<style scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
