<template>
  <div>

    <md-switch v-model="form.status" value="1">
      <span v-if="form.status">Ativo</span>
      <span v-if="!form.status">Inativo</span>
    </md-switch>

    <hr />
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="first-name">Nome</label>
          <md-input name="first-name" autocomplete="given-name" v-model="form.name" :disabled="sending" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="surname">Sobrenome</label>
          <md-input name="surname" autocomplete="given-name" v-model="form.surname" :disabled="sending" />
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="profession">Profissão</label>
          <md-input name="profession" autocomplete="given-name" v-model="form.profession" :disabled="sending" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="email">Email</label>
          <md-input name="email" autocomplete="given-name" v-model="form.email" :disabled="sending" />
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="phone">Telefone</label>
          <md-input name="phone" autocomplete="given-name" v-model="form.phone" :disabled="sending" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="city">Cidade</label>
          <md-input name="city" autocomplete="given-name" v-model="form.city" :disabled="sending" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="uf">Estado</label>
          <md-input name="uf" autocomplete="given-name" v-model="form.uf" :disabled="sending" />
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <p><strong>Background</strong></p>
        <img :src="form.background" />
      </div>
    </div>
    <hr />
    <div class="md-title">Dados profissionais</div>
    <div class="md-layout">
      <div class="md-layout-item">
        <img :src="form.company_avatar" style="float:left;width: 40px;margin-right: 20px;" />
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="company_name">Nome</label>
          <md-input name="company_name" autocomplete="given-name" v-model="form.company_name" :disabled="sending" />
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="company_email">E-mail</label>
          <md-input name="company_email" autocomplete="given-name" v-model="form.company_email" :disabled="sending" />
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="company_phone">Telefone</label>
          <md-input name="company_phone" autocomplete="given-name" v-model="form.company_phone" :disabled="sending" />
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <label for="company_facebook">Facebook</label>
          <md-input name="company_facebook" autocomplete="given-name" v-model="form.company_facebook" :disabled="sending" />
        </md-field>
        <a :href="form.company_facebook" target="_blank" v-show="form.company_facebook">
          Ver
        </a>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="company_instagram">Instagram</label>
          <md-input name="company_instagram" autocomplete="given-name" v-model="form.company_instagram" :disabled="sending" />
        </md-field>
        <a :href="form.company_instagram" target="_blank" v-show="form.company_instagram">
          Ver
        </a>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="company_pinterest">Pinterest</label>
          <md-input name="company_pinterest" autocomplete="given-name" v-model="form.company_pinterest" :disabled="sending" />
        </md-field>
        <a :href="form.company_pinterest" target="_blank" v-show="form.company_pinterest">
          Ver
        </a>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="company_linkedin">Linkedin</label>
          <md-input name="company_linkedin" autocomplete="given-name" v-model="form.company_linkedin" :disabled="sending" />
        </md-field>
        <a :href="form.company_linkedin" target="_blank" v-show="form.company_linkedin">
          Ver
        </a>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label for="company_website">Site</label>
          <md-input name="company_website" autocomplete="given-name" v-model="form.company_website" :disabled="sending" />
        </md-field>
        <a :href="form.company_website" target="_blank" v-show="form.company_website">
          Ver
        </a>
      </div>
    </div>

    <hr />

    <div class="md-layout-item">
      <md-button to="/admin/users" class="back">Voltar</md-button>
      <md-button @click="update">Salvar</md-button>
    </div>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import axios from "axios";

export default {
  name: "Form",
  mixins: [validationMixin],
  data() {
    return {
      form: {},
      status: 0,
      button: parseInt(this.$route.params.type) > 0 ? 'Update' : 'Create',
      message: "",
      sending: false,
      showSnackbar: false,
      users: this.$store.state.admin.users,
      user: this.$store.state.user,
    }
  },
  mounted() {
    if (parseInt(this.$route.params.type) > 0) {
      this.$store.commit('toggleLoading')
      if (this.users.length < 1) {
        axios
          .get(`${window.api}users/`)
          .then(response => {
            const user = response.data.filter((item) => item.id === parseInt(this.$route.params.type));
            this.form = user[0]
            this.status = this.form.status === 1 ? '1' : null;
            this.form.pwd = '';
            this.$store.commit('toggleLoading')
          })
      } else {
        const user = this.users.filter((item) => item.id === parseInt(this.$route.params.type));
        this.form = user[0]
        this.status = this.form.status === 1 ? '1' : null;
        this.form.pwd = '';
        this.$store.commit('toggleLoading')
      }
    }
  },
  methods: {
    update() {
      this.$store.commit('toggleLoading')
      this.sending = true;
      axios
        .post(
          `${window.api}users/${this.form.id}/admin/edit/`,
          {...this.form}
        )
        .then(() => {
          this.message = "Usuário atualizado";
        })
        .catch(() => {
          this.message = "Alguma coisa deu errado, tente novamente mais tarde";
        })
        .finally(() => {
          this.sending = true;
          this.showSnackbar = true;
        });
    }
  }
};
</script>

<style scoped>
.back {
  float: left;
}
.save {
  float: right;
}
</style>
