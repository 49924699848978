<template>
  <main>
    <md-app>
      <md-app-toolbar>
        <Bar />
      </md-app-toolbar>
      <md-app-drawer md-permanent="full">
        <Menu />
      </md-app-drawer>
      <md-app-content>
        <div class="breadcrumbs">
          <router-link to="/admin">Home</router-link> / <router-link to="/admin/users">Usuários</router-link> / Form
        </div>
        <Form />
      </md-app-content>
    </md-app>
  </main>
</template>

<script>
import Bar from '../../../components/admin/Bar';
import Menu from '../../../components/admin/Menu';
import Form from '../../../components/admin/user/Form';

export default {
  name: "AdminUser",
  components: {
    Menu,
    Form,
    Bar,
  },
};
</script>
