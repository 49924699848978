<template>
  <md-card class="holder">
    <md-card-header>
      <div class="md-title">
        Meu ambiente
      </div>
    </md-card-header>
    <md-card-content>
      <form novalidate @submit.prevent="validateUser">
        <md-field :class="getValidationClass('email')">
          <label for="email">Usuário</label>
          <md-input
            type="email"
            name="email"
            id="email"
            autocomplete="email"
            v-model="form.email"
            :disabled="sending"/>
          <span class="md-error" v-if="!$v.form.email.required">O usuario é obrigatório</span>
        </md-field>
        <md-field :class="getValidationClass('password')">
          <label for="password">Senha</label>
          <md-input
            type="password"
            name="password"
            id="password"
            v-model="form.password"
            :disabled="sending"/>
          <span class="md-error" v-if="!$v.form.password.required">A senha é obrigatória</span>
        </md-field>
        <md-button type="submit" class="submit md-primary md-raised">Login</md-button>
      </form>
    </md-card-content>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </md-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import router from "@/router";

export default {
  name: "LoginForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      userSaved: false,
      sending: false,
      lastUser: null,
      message: "",
      showSnackbar: false,
    }
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.login()
      }
    },
    login() {
      if (
        this.form.email === 'admin' &&
        this.form.password === 'hL11!4s(9ur9'
      ) {
        this.$store.commit("toggleLoggedUser", {name: 'Admin', role: 1});
        router.push({ name: "admin" });
      } else {
        this.message = "Usuário não encontrado";
        this.showSnackbar = true;
      }
    },
  },
};
</script>
