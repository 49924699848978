<template>
  <main id="home">
    <LoginForm />
  </main>
</template>

<script>
import { name, version } from "../../package.json";
import router from "@/router";
import LoginForm from "@/components/user/LoginForm.vue";

export default {
  name: "home",
  components: {
    LoginForm,
  },
  mounted() {
    let user = {}
    if (window.localStorage.getItem(`${name}-${version}`)) {
      user = JSON.parse(atob(window.localStorage.getItem(`${name}-${version}`)));
      this.$store.commit("toggleLoggedUser", user);
      if (user.role === 1) {
        router.push({name: "admin"});
      }
    }
  }
};
</script>
