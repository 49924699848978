<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
import { env, name, version } from "../package.json";
import Vue from "vue";
import VueEasyLightbox from 'vue-easy-lightbox'
import VueMaterial from "vue-material";
import VueMoment from 'vue-moment'
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import moment from 'moment-timezone'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueVirtualScroller from 'vue-virtual-scroller'

Vue.use(VueVirtualScroller)
Vue.use(CKEditor);
Vue.use(VueMaterial);
Vue.use(VueMoment, { moment });
Vue.use(VueEasyLightbox);

window.api = env.prod.endpoint;

export default {
  data() {
    return {
      loggedUser: this.$store.state.loggedUser,
    };
  },
  mounted() {
    let user = {}
    if (window.localStorage.getItem(`${name}-${version}`)) {
      user = JSON.parse(atob(window.localStorage.getItem(`${name}-${version}`)));
      this.$store.commit("toggleLoggedUser", user);
    }
  },
};
</script>

<style>
@import "../node_modules/vue-search-select/dist/VueSearchSelect.css";
@import "../node_modules/vue-wysiwyg-lite/dist/vueWysiwyg.css";
@import "../node_modules/@ag-grid-community/all-modules/dist/styles/ag-grid.css";
@import "../node_modules/@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.loading {
  position: relative;
  clear: left;
}

.loading .holder {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -15px;
}

.loading .holder .dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.loading .holder .dot-flashing::before,
.loading .holder .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.loading .holder .dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.loading .holder .dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.md-layout-item {
  margin: 0 5px;
}

.md-app {
  height: 100vh;
}

#home {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home .holder {
  max-width: 350px;
  width: 100%;
}

#home .holder .md-title {
  text-align: center;
}

#home .holder .md-title img {
  height: 40px;
  margin-bottom: 20px;
}

#home form {
  width: 100%;
}

#form {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
